/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Harriet247",
  title: "Hi all, I'm Harriet",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1E9iy5DJTY8HqoIwSUxZSHFGPuF7YXcbk/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/harriet247",
  linkedin: "https://www.linkedin.com/in/harriet-guo-9a0696184/",
  gmail: "harriet98kwok@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of British Columbia",
      logo: require("./assets/images/UBC-Logo-1.jpeg"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2017 - May 2022",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "60%"
    },
    {
      Stack: "Programming",
      progressPercentage: "90%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Dev Engineer I",
      company: "Amazon",
      companylogo: require("./assets/images/amazon-logo.jpeg"),
      date: "Sep 2022 – Mar 2023",
      desc: "Java, JUnit, AWS IAM, AWS S3, Swagger UI",
      descBullets: [
        "Designed and implemented an automated, scalable and robust internal tool which optimizes testing all service APIs by one click away",
        "Discovered and resolved an unknown critical networking issue that impacts the service’s AWS VPC connection to be onboarded by all legacy services",
        "Collaborated with senior engineers and mentors to work on low level designs of health insurance APIs that satisfy the HIPAA compliance",
        "Constructed migration plans for the health insurance service’s onboarding to related payment platforms",
        "Built solid knowledge about health insurance API designs"
      ]
    },
    {
      role: "Web and Mobile application QA",
      company: "BGC Engineering Inc.",
      companylogo: require("./assets/images/bgc-logo.png"),
      date: "Sep 2019 – Dec 2019",
      desc: "JavaScript, PostgreSQL, Datadog, FIgma, Microsoft Azure, Jira",
      descBullets: [
        "Constructed how-to articles and test plans for both web and mobile application, and a hololens project",
        "Developed collaboratively API tests in Postman with another QA team member, specifically with regards to errorhandling workflows between the API and the PostgreSQL database",
        "Assisted in investigations for failed e2e merge tests and participated in the testing for new release deployments",
        "Provided technical support to engineers and generated bug reports for the software product team"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  projects: [
    {
      image: require("./assets/images/pikachu.jpeg"),
      projectName: "Pokedex",
      projectDesc: "A wikipedia for pokemon lovers and a forum",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://pokedex-website-2021.herokuapp.com/#/"
        },
        {
          name: "Visit Repo",
          url: "https://github.com/harriet247/Pokedex-1"
        }
      ]
    },
    {
      image: require("./assets/images/poweroutage.png"),
      projectName: "Power outage visualization",
      projectDesc: "A data visualization d3.js project for power outage events in Mainland USA from 2015 - 2021.",
      footerLink: [
        {
          name: "Visit Repo",
          url: "https://github.com/harriet247/PowerOutageVisualization"
        }
      ]
    },
    {
      image: require("./assets/images/campusExplorerLogo.png"),
      projectName: "Campus Explorer",
      projectDesc: "A web app for helping UBC students to create reasonable course schedule.",
      footerLink: [
        {
          name: "Visit Repo",
          url: "https://github.com/harriet247/UBC-Camput-Exploer"
        }
      ]
    },
    {
      image: require("./assets/images/imageCompressionLogo.png"),
      projectName: "Image Compression",
      projectDesc: "A local image compressor that gives back a process gif after generating a compressed image result.",
      footerLink: [
        {
          name: "Visit Repo",
          url: "https://github.com/harriet247/ImageCompression"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

// const achievementSection = {
//   title: emoji("Achievements And Certifications 🏆 "),
//   subtitle:
//     "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

//   achievementsCards: [
//     {
//       title: "Google Code-In Finalist",
//       subtitle:
//         "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
//       image: require("./assets/images/codeInLogo.webp"),
//       imageAlt: "Google Code-In Logo",
//       footerLink: [
//         {
//           name: "Certification",
//           url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
//         },
//         {
//           name: "Award Letter",
//           url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
//         },
//         {
//           name: "Google Code-in Blog",
//           url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
//         }
//       ]
//     },
//     {
//       title: "Google Assistant Action",
//       subtitle:
//         "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
//       image: require("./assets/images/googleAssistantLogo.webp"),
//       imageAlt: "Google Assistant Action Logo",
//       footerLink: [
//         {
//           name: "View Google Assistant Action",
//           url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
//         }
//       ]
//     }
//   ],
//   display: true // Set false to hide this section, defaults to true
// };


const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or want to say hi? My Inbox is open for all.",
  email_address: "harriet98kwok@gmail.com"
};


const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  contactInfo,
  isHireable
};
